//import { ModuleResolutionKind } from 'typescript';
import { ActionResolutionReason } from '@/features/insights/types/insights';

export default {
  key: 'en-US',
  strings: {
    'uptake-component-library': {
      pagination: {
        viewing: 'Viewing {current} of {total} Results',
        first: 'First',
        last: 'Last',
      },
      userProfile: {
        userProfile: 'User Profile',
        manageAccount: 'Manage Account',
        logOut: 'Log Out',
      },
      multiselect: {
        cancel: 'Cancel',
        go: 'Go',
        'select-all': 'Select All / Deselect All',
      },
      errorMessage: {
        dataNotFound: 'Data Not Found',
      },
      search: {
        buttonLabel: 'Search',
      }
    },
    header: {
      search: {
        placeholder: 'Asset ID for Asset Viewer'
      },
      'upgrade-message': {
        title: 'Uptake',
        content:
          'provides asset-intensive organizations rapidly configurable decision support and PM strategies to optimize operations and maintenance spend across assets based upon costs, criticality and risks at the lowest total cost of ownership (TCO). Differentiators:',
        differentiators: [
          'Asset Strategy Library with ACE Reliability Algorithms',
          'Data model which supports a one stop shop to analyze data across multiple systems / sites',
          'Risk Analysis with data science engines which calculate the probability of failure & consequence of the failure',
          'Pre-built analytic widgets to help analyze your data such as costs, outliers, mean time between failure',
          'Compliant with ISO 14224',
          'Proven ability to deliver return on investment in less than 60 days',
          'Average 10x return on investment',
        ],
        'learn-more': {
          message: 'Would you like to learn more?',
          demo: 'Schedule a Free Demo',
          info: 'More Information',
        },
      },
    },
    riskExplorer: {
      pageTitle: 'RISK EXPLORER',
      'units(s)': 'Unit(s)',
      vehicleFamily: 'Vehicle Family',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'Serial #(s)',
      topTen: 'TOP 10',
      vehiclesAtRisk: 'VEHICLES AT RISK',
      risksByAsset: 'RISKS by ASSET',
      riskByAssetDescription: 'Select an asset below to see its risk details.',
      userSavedFilters: 'SAVED FILTER(S)',
      assetRiskFactors: 'ASSET RISK FACTORS',
      riskSummary: 'Overall Risk Summary',
      readiness: 'READINESS',
      overall: 'overall',
      vehicles: 'vehicles',
      top: 'top',
      current: 'Current',
      actioned: 'actioned',
      insight: 'insights',
      riskFactor: 'Risk factors',
      chartDescription: 'The chart below breaks down all at-risk vehicles by their current risk level. Click on the chart segment to see the percentage of at-risk vehicles that are at the selected risk level.',
      allVehiclesModal: {
        title: 'Current',
        vehiclesAtRiskTitle: 'Current Vehicles At Risk',
      },
      buttons:{
        allVehicles: 'View All Vehicles'
      }
    },
    topInsights: {
      pageTitle: 'ASSETS + INSIGHTS + CASES',
    },
    assetInsightsSummary: {
      pageTitle: 'ASSET/INSIGHTS SUMMARY'
    },
    topInsightsFed: {
      pageTitle: 'ASSETS + INSIGHTS',
    },
    userManagement: {
      confirmDeleteUser: 'Do you want to delete this user?',
      pageTitle: 'User Management',
      LastName: 'Last Name',
      FirstName: 'First Name',
      EmailAddress: 'Email Address',
      PhoneNumber: 'Phone Number',
      Role: 'Role',
      Unit: 'Unit',
      UserStatus: 'User Status',
      addUser: 'ADD USER',
      updateUser: 'UPDATE USER',
      userName: 'USER NAME',
      email: 'EMAIL',
      accessLevel: 'ACCESS LEVEL',
      selectLevel: 'Select User Access Level',
      validation: 'Please fill all the required fields with valid data',
      buttons: {
       resetForm: 'Reset Form',
       cancel: 'CANCEL',
       addNewUser: 'Add new user',
       updateUser: 'Update user',
       submit: 'Confirm'
      },
      confirmation: {
        addUserTitle: 'Do you want to add this user?',
        updateUserTitle: 'Do you want to update this user?',
        addUser: 'Please confirm that you want to add user {name}.',
        updateUser: 'Please confirm that you want to update user {name}.'
      },
    },
    filtersModal: {
      pageTitle: 'FILTER MANAGEMENT',
      sectionOne: 'STEP 1 FILTERS',
      sectionTwo: 'STEP 2 FILTERS',
      MEF: 'MEF(s)',
      MSC: 'MSC(s)',
      REGIMENT: 'REGIMENT(s)',
      BN: 'BN(s)',
      AACUIC: 'AAC/UIC(s)',
      VEHICLEFAMILY: 'VEHICLE FAMILY',
      TAMCN: 'TAMCN(s) / NOMENCLATURE(s)',
      SERIAL: 'SERIAL #(s)',
    },
    userProfile: {
      userProfile: 'User Profile',
      manageAccount: 'Manage Account',
      logOut: 'Log Out',
      logoutModal: {
        title: "You've logged out of your previous session.",
        content: 'Please sign back in to start a new session.',
      },
      userData:{
        userName: 'Username: ',
        tenantCode: 'Tenant Code: ',
        email: 'Email: ',
        impersonator: 'Impersonator: '
      }
    },
    RiskByAsset: {
      title: 'RISKS by ASSET',
      description: 'Select an asset below to see its risk details.',
      riskPercentage: 'Risk%',
      vehicleNumbers: 'Vehicle Numbers',
    },
    VehiclesAtRisk: {
      riskPercentage: 'Risk%',
      vehicleNumber: 'Vehicle Number',
      riskSummary: 'Risk Summary',
      vehicleRediness: 'Vehicle Rediness',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'Serial #(s)',
      missionReadyVehicles: 'Mission-Ready Vehicles',
      vehiclesAtRisk: 'VehiclesAtRisk:',
    },
    OverViewByVehicleRisk: {
      Title: 'Overview',
      description: 'Select an asset below to see its risk details.',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'Serial #(s)',
      riskPercentage: 'Risk%',
      NumberChangePercentage: 'NumberChangePercentage',
      DateChangePercentage: 'DateChangePercentage',
    },
    Methodology: {
      pageTitle: 'Methodology',
      pageSubTitle: 'behind the metrics',
      Description:
        "The risk score is a calculation which represents the overall severity of each vehicle's health based on predicted failures, operating behavior, and parts age. A high risk score indicates an elevated risk of problems which a maintenance or supply action may resolve. A low risk score indicates a healthy vehicle ready for missions.",
    },
    Error: {
      DataNotFound: 'DataNotFound',
    },
    methodology: {},
    riskExplorerFilters: {
      pageTitle: 'RISK EXPLORER FILTERS',
      'units(s)': 'Unit(s)',
      vehicleFamily: 'Vehicle Family',
      'tamcn(s)': 'TAMCN(s)',
      'serial#(s)': 'Serial #(s)',
      'nomenclature(s)': 'Nomenclature(s)',
      'manufacturer-name(s)': 'Manufacturer Name(s)',
      'commission-date(s)': 'Commission Date(s)',
      'manufacturer-year(s)': 'Manufacturer Year(s)',
    },
    pagination: {
      viewing: 'Viewing {current} of {total} Results',
      first: 'First',
      last: 'Last',
      rowsPerPage: 'Rows per page',
      viewPager: 'Viewing { start }-{ end } of { total }'
    },
    tableControls: {
      orderBy: 'Order by',
      fields: {
        date: 'Date',
        author: 'Author',
      },
    },
    login: {
      pageTitle: 'Sign In',
      stepOne: 'User Information',
      stepTwo: 'Use and Consent',
      StepOneTitle: 'STEP 2: USE AND CONSENT',
      StepOneFormText: '',
      checkboxAcknowledgement:
        'By checking this box, I acknowledge that I have read and accept the Use and Consent terms above.',
      copyrightText: 'Copyright {year} Uptake Technologies, Inc.',
      privacyNotice: 'Privacy Notice',
    },
    caseActions: {
      createCase: 'Create Case',
      selectCase: 'Select a Case',
      title: 'Case Title',
      description: 'Description',
      addNewCaseButton: 'Add New Case',
      cancel: 'Cancel',
      confirmation: 'DO YOU WANT TO CREATE',
      information: "* This description will be added to all case that have been selected."
    },
    addTocCase: {
      caseAddedTitle: 'Insight(s) added to Case',
      caseAddedMessage: '{length} Insight(s) added to Case.',
      caseFailedToAdd: 'No Insights(s) added',
      caseFailedToAddMessage: '{length} Insight(s) failed to add to Case.',
      caseToInsight: 'Add Insight to Case',
      caseToInsights: 'Add Insights to Case',
    },
    closeCase:{
      title: 'Close Case',
      confirmation: 'How would you like to resolve the insights associated with this case?',

    },
    uavFleet: {
      breadcrumb: {
        title: 'Asset Viewer',
      },
      downloadPdf: {
        description: 'While monitoring your equipment, Uptake produced the following insight:',
        downloadButton: 'Download Insight',
        assetDetails: 'Asset Details',
        insightDetails: 'Insight Details',
        vin: 'VIN',
        model: 'Model',
        manufacturer: 'Manufacturer',
        modelYear: 'Model Year',
        assetInsight: 'Asset Insight',
        supportingEvidence: 'Supporting Evidence',
        recommendedActions: 'Recommended Actions',
        insightHiddenByAdmin: 'Insight Hidden By Admin'
      },
      assetNumber: 'Asset #',
      asset: 'Asset',
      riskLevel: 'Risk Score',
      details: 'DETAILS',
      recentHistory: 'RECENT HISTORY',
      fleetOverview: 'OVERVIEW',
      current: 'current',
      activeInsights: 'Active Insights',
      recent: 'recent',
      insight: 'INSIGHTS',
      cases: 'CASES',
      users: 'USERS',
      connectors: 'CONNECTORS',
      viewInsights: 'insights',
      workOrders: 'WORK ORDERS',
      openCases: 'Open Cases',
      viewWorkOrders: 'Work Orders',
      top: 'top',
      riskFactor: 'Risk factors',
      stillActiveTooltip:
        'A checkmark means recently returned data indicates this issue likely to occur. A blank table cell means the data has returned to normal operating behavior.',
      additionalAnalysis: 'ADDITIONAL ANALYSIS',
      recommendedAction: 'Recommended Action',
      summary: 'Summary',
      faults: 'Events in the last',
      insightDetail: {
        actioned: 'Actioned',
        insights: 'INSIGHTS',
        overview: 'OVERVIEW',
        HiddenByAdmin: 'Hidden By Admin',
        insightSeverity: 'Guidance',
        InsightEnd: 'Last Detected',
        InsightStart: 'First Detected',
        InsightType: 'Type',
        InsightStatus: 'Status',
        heading: 'Insight Details',
        subsystem: 'Subsystem',
        ReadMore: 'Read More',
        ReadLess: 'Read Less',
        riskBySubsystem: 'Risk by SUBSYSTEM',
        engineOverheating: 'ENGINE OVERHEATING INSIGHTS',
        insightStatusTransform: {
          ASSIGNED_TO_CASE: 'Assigned To Case',
          ACCEPTED: 'Accepted',
          DISREGARDED: 'Disregarded',
          NEW: 'Awaiting Review',
        },
        insightCategoryTransform: {
          WORK_ORDER: 'Work Order',
          FLUID: 'Fluid',
          SENSOR: 'Sensor',
          FAULT: 'Fault',
        },
        supportingEvidence: 'Supporting Evidence',
        actionDetailsTitle: 'ACTION DETAILS',
        actionDetailsNotes: 'Notes',
        assignedToCase: 'Assigned to case',
        caseOpen: 'Open',
        actionDetails: {
          [ActionResolutionReason.ActionTaken]: 'An issue was found and action was taken.',
          [ActionResolutionReason.ActionOnHold]: 'An issue was found and action was placed on hold.',
          [ActionResolutionReason.NoIssueFound]: 'No issue was found.',
          [ActionResolutionReason.InconclusiveInspection]: 'Inspection was inconclusive.',
          [ActionResolutionReason.Duplicate]: 'Insight is a duplicate.',
          [ActionResolutionReason.Ignore]: 'Ignore.',
          [ActionResolutionReason.Other]: '',
        },
      },
      insightsTable: {
        emptyTableMessage: 'No insights associated with this asset',
      },
      workOrderDetail: {
        assetId: 'Asset ID',
        createdOn: 'Created On',
        description: 'Description',
        notes: 'Notes',
        task: 'Task',
        tasks: {
          meterReading: 'Meter Reading',
          meterReadingUnit: 'Meter Reading Unit',
          totalCost: 'Total Cost',
          workCode: 'Work Code',
        },
        workCompletedDate: 'Work Completed Date',
        workOrders: 'Work Orders',
        workOrderDetails: 'Work Order Details',
        workorderId: 'Work Order ID',
      },
      caseDetail: {
        description: 'DESCRIPTION',
        dateCreated: 'DATE CREATED',
        caseDetails: 'CASE DETAILS',
        caseStatus: 'CASE STATUS',
        cases: 'CASES',
        commentSection: 'Comment log',
        preferredUsername: 'CREATED BY',
        closedUsername: 'CLOSED BY',
      },
      casesTable: {
        emptyTableMessage: 'No cases associated with this asset',
      },
      risk: {
        zones: {
          veryLow: 'Very Low',
          low: 'Low',
          medium: 'Medium',
          high: 'High',
          veryHigh: 'Very High',
          unknown: 'Unknown',
        },
      },
      workOrdersTable: {
        emptyTableMessage: 'No work orders associated with this asset',
      },
      recentInsightHistory: {
        title: 'RECENT INSIGHTS',
        titleTooltip: '30 Days Since Last Detected',
        insightName: 'Insight Name',
        stillActive: 'Still Active',
        subSystem: 'Subsystem',
        firstDetected: 'First Detected',
        lastDetected: 'Last Detected',
        noInsights: 'No recent Insights in the last 30 days',
        viewAllInSights: 'View all Insights',
        details: 'Details',
        noData: 'NO DATA',
        noFaults: 'No faults detected in past {daysCount} days',
        realTime: 'NEAR REAL-TIME',
        summary: 'DAILY SUMMARY',
      },
    },
    connector: {
      confirmDeleteConnector: 'Do you want to delete this connector?',
      connectors: 'CONNECTORS:',
      selectConnector: 'Select Connector',
      connectorName: 'CONNECTOR NAME',
      credentialsTitle: 'ENTER CREDENTIALS',
      dataLakeAutomation: 'DataLake Automation',
      invalidConnectorName : 'Invalid connector name',
      testResult: 'TEST RESULT',
      credentialsSaved: 'CREDENTIALS SAVED',
      connectorType: 'CONNECTOR: {name}',
      addConnector: 'ADD CONNECTOR',
      updateConnector: 'UPDATE CONNECTOR',
      connectorNameRules:{
        ruleOne: 'Connector names are limited to 16 chars',
        ruleTwo: 'Only lower-case allowed',
        ruleThree: 'Digits allowed after the first char',
        ruleFour: 'Single dashes allowed',
      },
      buttons: {
        confirm: 'Confirm',
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
        back: 'Back',
        apply: 'Apply',
        reset: 'Reset Form',
        verify: 'Verify Credentials',
      },    
    },
    alert: {
      errorTitle: 'Data did not load',
      errorMessage:
        'Something went wrong. Please refresh the page and try again. If the error persists, please contact',
      mail: "gov-support{'@'}uptake.com",
      mailFleet: "help{'@'}uptake.com",
    },
    buttons: {
      search: 'Search',
    },
    userSavedFilters: {
      header: 'Manage Saved Filters',
      editFilterTitle: 'Edit Saved Filter',
      createFilterTitle: 'Create New Filter',
      newFilterName: 'New Filter Name',
      allAssets: 'All Assets',
      closeFlyout: 'Close flyout',
      count: 'of {total} Assets meet the selected criteria',
      organizationalFilters: 'Organizational Filters',
      assetFilters: 'Asset Filters',
      step: 'Step {step} of 2 Filters',
      currentStep: '{currentStep}',
      buttons: {
        close: 'Close',
        cancel: 'Cancel',
        save: 'Save',
        saveAs: 'Save As',
        apply: 'Apply',
        next: 'Next',
        reset: 'Reset All Filters',
      },
      confirmationMessages: {
        invalidText: {
          title: ' No invalid filters |  Invalid Saved Filter |   Invalid Saved Filters',
          message:
            'No invalid filters |  There is a saved filter that is no longer valid due to your current permission settings.|  There are saved filters that are no longer valid due to your current permission settings.',
        },
        createCase:{
          title: 'Do you want to create "1 new case"',
          message: 'Please confirm that you want to create "1 New Case" from the "{insightCount} Selected Insights". Click the Cases Card to view cases for these insights.',
        },
        createXCase:{
          title: 'Do you want to create "{insightCount} new cases"',
          message: 'Please confirm that you want to create "{insightCount} New Cases" from the "{insightCount} Selected Insights". Click the Cases Card to view cases for these insights',
        },
        buttons: {
          confirm: 'Confirm',
          save: 'Save',
          cancel: 'Cancel',
          deleteAll: 'No invalid filters |  Delete Invalid Filter |   Delete All Invalid Filters',
          alt: 'Close flyout',
        },
      },
      saved: {
        title: 'Saved Filters',
        buttons: {
          default: 'Default',
          delete: 'Delete',
          edit: 'Edit',
          copy: 'Copy',
        },
      },
      flyout: {
        title: 'Active Filters',
        tooltip: 'Note: All data will be filtered by these values unless specified otherwise.',
        placeholder: 'Select a Filter',
      },

      createNewFilterInput: {
        placeholder: 'Untitled Filter',
      },
    },
    fleetUserSavedFilters: {
      region: 'Region(s)',
      terminals: 'Terminal(s)',
      assets: 'Asset(s)',
      modalTitle: 'FILTER ASSETS',
    },
    faultsTimeline: {
      title: 'FAULTS TIMELINE',
      by: 'by',
      subTitle: 'Events in the last 30 days',
      footerMessage: '*Click any element on the X axis or in the legend to toggle it ON/OFF.',
      reset: 'Reset',
    },
    componentLib: {
      dstableStrings: {
        noContentDefault: 'No data',
      },
    },
    commentSection: {
      addComment: 'Add a Comment',
      add: 'ADD',
    },
    adminTabs: {
      user:'Manage Users',
      connector:'Manage Connectors',
      autoCaseCreate: 'Manage Auto Case',
      insight: 'Manage Insights',
      title: 'ADMINISTRATOR SETTINGS',
    },
    manageLegend:{
      title: 'Manage Insights Legend',
      instructionMessage: 'You can adjust the legend display value for each severity input field.',
      validationMessage: {
        partOne: 'Avoid special characters ',
        specialChar: '{special} ',
        partTwo: 'or punctuation and keep in mind the limit of ',
        charCount: '30', 
        partThree: 'charaters.',
      },
    },
    manageInsights:{
      title: 'MANAGE INSIGHTS',
      onLabel: 'On',
      offLabel: 'Off',
      saveButtonLabel: 'Save',
      filterModalTitle: 'Insight Case Configuration Filter',
      filterModalConfigLabel: 'APPLY',
      inputSearchPlaceholderText: 'Search By Name or Subsystem',
      autoOpenCaseTitleTemplate: 'Update Auto Case Config',
      autoOpenCaseDescriptionTemplate: 'Update Auto Case Config',
      saveLegendMessage:{
        title: 'Save Legend',
        message: 'Legend changes are saved successfully',
      },
      updateMessage: {
        title: 'Case Auto Open Configuration',
        message: 'Auto open case configuration updated successfully',
      },
      createMessage:{
        title: 'Manage Insight Configuration',
        message: 'Configuration saved successfully',
      },
      errorMessage: {
        title: 'Error',
        message: 'Operation filed due to internal server error',
      },
      baseLegends: {
        CATASTROPHIC: 'Action Immediately',
        CRITICAL: 'Action Earliest Opportunity',
        MARGINAL: 'Action Next Visit',
        NEGLIGIBLE: 'Next Preventive Maintenance',
      },
      filter: {
        title: 'All Filters',
        instructionMessage: 'Select filters to apply',
      },
    },
    createCase:{
      title: 'Select an option below to create case(s)',
      subTitle: '{insightCount} insight(s) selected',
      optionOne: 'Create {insightCount} New Cases for {insightCount} Selected insight(s)',
      optionTwo: 'Create 1 New Cases for {insightCount} Selected insight(s)',
    },
    notificationManagement: {
    alert: 'There is no email address currently associated with this user. Please contact',
    support: 'for support.',
    title: 'NOTIFICATION MANAGEMENT',
    alertTitle: 'EMAIL ADDRESS MISSING',
    loading: 'Sending and Processing Data...',
    confirmation: "Are you sure you want to exit this notification management without saving?",
    confirmTitle: 'CONFIRM EXIT',
    confirmExitButtonTitle: 'Exit Without Saving',
    saveNofificationsButton: 'Save Notifications',
    buttons:{
      save:'Save'
    }
    },
    aicCards:{
      viewActions: 'View Insights Requiring Action',
      viewOpenCases: 'View Open Cases',
      newCase: 'New Cases Added',
      createCase: {
        confirmation:'Please confirm that you want to create {records} new case(s) from the selected insights.',
        click:'Click the ',
        cases:'Cases',
        card:' card to view the cases for these insights.'
      },
      buttons: {
        cancel: 'Cancel',
        confirm: 'Confirm',
      }
    },
    quickSight:{
      error: 'There seems to be a problem loading this dashboard, try reloading the page and if the problem persists contact support.',
      reload: 'RELOAD PAGE'
    },
    dsModal:{
      buttons: {
        close: 'Close',
        cancel: 'Cancel',
        download: 'DOWNLOAD (.CSV)',
      },
      download:{
        progress: 'Download In Progress... ',
        warning: 'Please do not navigate away from this page or the download will not complete. This may take a few minutes.',
        message: 'The download will include all the results for this table. The download time will vary based on the number of results. In order to reduce download time, consider reducing the results with a saved filter.',

      }
    }
  },
  numberFormats: {
    percent: {
      style: 'percent',
    },
  },
  datetimeFormats: {
    short: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
    },
    shortTime: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
    },
    shortTimeTimezone: {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      timeZoneName: 'short',
    },
  },
};
