import { defineStore } from 'pinia';
import { useAPI } from '@/api/client/apiClient';
import { useLocalStorage } from '@/utils/localStorageUtils';

type TenantState = {
  config: any;
  insightPreferenceConfig: any;
  featureSubscriptions: any;
};

const api = useAPI();

export default defineStore('tenant', {
  state: (): TenantState => ({
    config: {},
    insightPreferenceConfig: {},
    featureSubscriptions: {},
  }),

  actions: {
    async setTenantConfig() {
      try {
        const response = await api.Tenant.getTenantConfig();
        const data = response.map((item: any) => item.attributes);
        const tenantConfig: any = {
          product: import.meta.env.VITE_PRODUCT_MODE,
        };
        useLocalStorage().product = import.meta.env.VITE_PRODUCT_MODE;

        data.forEach((item: any) => {
          Object.keys(item).forEach((key) => {
            tenantConfig[key] = item[key];
          });
        });

        this.config = tenantConfig;
      } catch (error) {
        console.error('Failed while obtaining tenant config...\r\n', error);
      }
    },

    async setTenantFeatureSubscriptions() {
      this.featureSubscriptions = await api.Tenant.getTenantFeatureSubscriptions();

    },
    async setInsightPreferences() {
      this.insightPreferenceConfig = (await api.Tenant.getConfigurationDto('insight-preferences')).data;
    },
    async createConfiguration(critical: string, marginal: string, negligible: string, catastrophic: string) {
      await api.Tenant.createConfiguration(critical, marginal, negligible, catastrophic).then((response) => {
        this.config.insightPreferences = response.data.config.insightPreferences;
        return true;
      });
    },
    async updateConfiguration(
      configurationId: string,
      critical: string,
      marginal: string,
      negligible: string,
      catastrophic: string
    ) {
      await api.Tenant.updateConfiguration(configurationId, critical, marginal, negligible, catastrophic).then(
        (response) => {
          this.config.insightPreferences = response.data.config.insightPreferences;
          return true;
        }
      );
    },
  },

  getters: {
    tenantFilters: (state: TenantState) => {
      return state.config.filters;
    },
    customerAssetIdentifier: (state: TenantState) => {
      return state.config.aic_assets_table_search?.id || 'customer_asset_id'
    },
    horizontalNav: (state: TenantState): boolean => {
      return state.config.partnerConfiguration?.horizontalNav || false;
    },
    skipTermsAndConditions: (state: TenantState): boolean => {
      return state.config.partnerConfiguration?.skipTermsAndConditions || false;
    },
    hideTopToolbar: (state: TenantState): boolean => {
      return state.config.partnerConfiguration?.hideTopToolbar || false;
    },
    helpPDFPath: (state: TenantState): string => {
      return state.config.partnerConfiguration?.helpPDFPath || '';
    },
    styles: (state: TenantState): [string, string][] => {
      return Object.entries(state.config.partnerConfiguration?.styles || {});
    },
    quickSightDashboardsList: (state: TenantState): boolean => {
      return state.config.quickSightDashboards || [];
    },

    // Dark Flags
    manageAutoCaseDeployFlagEnabled: (state: TenantState): boolean => {
      return state.config.admin && state.config.admin.autoCase ? state.config.admin.autoCase.enabled : false;
    },
    manageInsightsDeployFlagEnabled: (state: TenantState): boolean => {
      return state.config.admin && state.config.admin.insights ? state.config.admin.insights.enabled : false;
    },
    manageConnectorsDeployFlagEnabled: (state: TenantState): boolean => {
      return state.config.admin && state.config.admin.connectors ? state.config.admin.connectors.enabled : false;
    },

    //Feature Subscriptions:
    casesFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.casesFeature ? state.featureSubscriptions.casesFeature.enabled : false;
    },
    bulkCaseCreateFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.bulkCaseCreate ? state.featureSubscriptions.bulkCaseCreate.enabled : false;
    },
    nearTimeFaultsFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.nearTimeFaults ? state.featureSubscriptions.nearTimeFaults.enabled : false;
    },
    workOrderAnalyticsFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.workOrderAnalytics
        ? state.featureSubscriptions.workOrderAnalytics.enabled
        : false;
    },
    buttonAcceptInsightsFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.buttonAcceptInsights
        ? state.featureSubscriptions.buttonAcceptInsights.enabled
        : false;
    },
    fleetInsightAssessmentDashboardFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.fleetInsightAssessmentDashboard
        ? state.featureSubscriptions.fleetInsightAssessmentDashboard.enabled
        : false;
    },
    repeatedRepairsDashboardFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.repeaterDashboard
        ? state.featureSubscriptions.repeaterDashboard.enabled
        : false;
    },
    remoteDiagnosticFeatureEnabled: (state: TenantState): boolean => {
      return state.featureSubscriptions.remoteDiagnostic
        ? state.featureSubscriptions.remoteDiagnostic.enabled
        : false;
    },
    bulkAddInsightsFeatureEnabled: (state: TenantState): boolean => {
      return state.config.uav ? state.config.uav.insights.table.cases.addInsights.bulk.enabled : false;
    },
  },
});
